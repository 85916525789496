import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import * as S from "./styled/styles";
import Layout from "../../components/Layout";
import HeaderArmazem from "../../components/HeaderArmazem";
import requests from "../../services/requests";
import { useSelector } from "react-redux";
import TitleC6 from "../../components/Typography/TitleC6";
import ABCAnalysisGraph from "../../components/Graphs/ABCAnalysisGraph";
import OrderStatusGraph from "../../components/Graphs/OrderStatusGraph";
import OrderTimingGraph from "../../components/Graphs/OrderTimingGraph";
import DeliveryStatusGraph from "../../components/Graphs/DeliveryStatusGraph";
import ReceiptTimingGraph from "../../components/Graphs/ReceiptTimingGraph";
import ReceiptOrderTrackingGraph from "../../components/Graphs/ReceiptOrderTrackingGraph";
import ReceiptProductTrackingGraph from "../../components/Graphs/ReceiptProductTrackingGraph";
import GraphWarehouseSla from "../../components/Graphs/GraphWarehouseSla";
import Card from "../../components/Cards";
import ClipLoader from "react-spinners/ClipLoader";
import permissions from "../../services/permissions";
import profile from "../../services/profile";
import Modal from "../../components/Modals";

const IndexPage = () => {
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(
        window.localStorage.getItem("warehouse-filter-client")
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-client"))
            : []
    );
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [selectedUf, setSelectedUf] = useState(
        JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.value !== null
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-state"))
            : { value: null, label: "TODOS" }
    );
    const searchPeriod = useSelector(state => state.period);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    // Recebimento
    const [dataReceipt, setDataReceipt] = useState({});
    const [loadReceipt, setLoadReceipt] = useState(false);
    const [openModalGraphReceipt, setOpenModalGraphReceipt] = useState(false);

    // Picking Packing
    const [dataPickingPack, setDataPickingPack] = useState({});
    const [loadPickingPack, setLoadPickingPack] = useState(false);

    // Expediçãp
    const [dataExpedition, setDataExpedition] = useState({});
    const [loadExpedition, setLoadExpedition] = useState(false);
    const [openModalGraphExpedition, setOpenModalGraphExpedition] = useState(false);
    const [tabModal, setTabModal] = useState("Análise de remessas por dia");

    // Função que gera os gráficos
    const showMetrics = () => {
        setDataReceipt({});
        setDataPickingPack({});
        setDataExpedition({});

        setLoadReceipt(true);
        setLoadPickingPack(true);
        setLoadExpedition(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            dashboard: "geral",
        };

        requests.dashboardRecebimento(filters).then(data => {
            if (!data.data.average) {
                data.data.average = "0";
            }

            setDataReceipt(data.data);
        });

        setLoadReceipt(false);

        filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "picking",
            dashboard: "geral",
        };

        requests.dashboardPickingPack(filters, "picking").then(data => {
            setDataPickingPack(data.data);
        });

        setLoadPickingPack(false);

        filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "expedicao",
            dashboard: "geral",
        };

        requests.remittanceAnalysisExpedition(filters).then(data => {
            if (data.data.average === 0) {
                data.data.average = '0';
            }
            setDataExpedition(data.data);
        });

        setLoadExpedition(false);
    };

    const actionModalGraphReceipt = () => {
        setOpenModalGraphReceipt(!openModalGraphReceipt);
    };

    const closeModalGraphReceipt = () => {
        setOpenModalGraphReceipt(false);
    };

    const actionModalGraphExpedition = () => {
        setOpenModalGraphExpedition(!openModalGraphExpedition);
        setTabModal("Análise de remessas por dia");
    };

    const closeModalGraphExpedition = () => {
        setOpenModalGraphExpedition(false);
    };

    //Função que gera a lista de clientes do filtro
    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (selectedClient?.length > 0 && search.length < 2) {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        } else if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    useEffect(() => {
        loadClientOptions();

        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
        }
    }, []);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
        }
    }, [searchPeriod, selectedClient, selectedUf]);

    return (
        <>
            <Layout>
                <HeaderArmazem
                    title="Armazém"
                    clientsOptions={clientsOptions}
                    setSelectedClient={setSelectedClient}
                    noClientOptionsMessage={noClientOptionsMessage}
                    loadClientOptions={loadClientOptions}
                    isLoadingClients={isLoadingClients}
                    setSelectedUf={setSelectedUf}
                    setMeta={setMeta}
                    dashboard={true}
                />
                <S.ContainerCards className="flex flex-wrap p-4">
                    {(permissions.recebimento || profile.role === "ROLE_SYSTEM_ADMIN") && (
                        <>
                            {/* RECEBIMENTO */}
                            <Card className={`w-3/12 md-full`}>
                                <ReceiptTimingGraph
                                    title="Prazos do recebimento"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                            </Card>
                            <Card className="w-3/12 md-full">
                                <ReceiptOrderTrackingGraph
                                    title="Tracking de pedido"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                            </Card>
                            <Card className="w-3/12 md-full">
                                <ReceiptProductTrackingGraph
                                    title="Tracking de produtos"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                            </Card>
                            <Card className="w-3/12 md-full">
                                {!dataReceipt.average ? (
                                    <>
                                        <Card>
                                            <TitleC6>Análise de remessas (média)</TitleC6>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        title="Análise de remessas (média)"
                                        data={dataReceipt}
                                        isLoading={loadReceipt}
                                        selectedClient={selectedClient}
                                        container="GraphSlaReceiptTotal"
                                        modality="byTotal"
                                        openModalGraphReceipt={openModalGraphReceipt}
                                        actionModalGraphReceipt={actionModalGraphReceipt}
                                    />
                                )}
                                <S.Footer>
                                    <Link to="/armazem/recebimento">
                                        <S.Button>Ver Recebimento</S.Button>
                                    </Link>
                                </S.Footer>
                            </Card>
                        </>
                    )}

                    {(permissions.estoque || profile.role === "ROLE_SYSTEM_ADMIN") && (
                        <>
                            {/*ESTOQUE */}
                            <Card className="w-full mt-4">
                                <ABCAnalysisGraph
                                    modality="ESTOQUE"
                                    title="Curva ABC - Estoque"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />

                                <S.Footer>
                                    <Link to="/armazem/estoque">
                                        <S.Button>Ver Estoque</S.Button>
                                    </Link>
                                </S.Footer>
                            </Card>
                        </>
                    )}

                    {(permissions.pickingepacking || profile.role === "ROLE_SYSTEM_ADMIN") && (
                        <>
                            {/* PICKING E PACKING */}
                            <Card className="w-3/12 mt-4 md-full">
                                <OrderTimingGraph
                                    modality="STATUS_PEDIDO"
                                    title="Status dos Pedidos"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                            </Card>
                            <Card className="w-9/12 mt-4 md-full">
                                <OrderStatusGraph
                                    modality="STATUS_PEDIDO"
                                    title="Andamento dos Pedidos"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                                <S.Footer>
                                    <Link to="/armazem/pickingepacking">
                                        <S.Button>Ver Picking & Packing</S.Button>
                                    </Link>
                                </S.Footer>
                            </Card>
                        </>
                    )}

                    {(permissions.expedicao || profile.role === "ROLE_SYSTEM_ADMIN") && (
                        <>
                            <Card className="w-3/12 mt-4">
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <TitleC6>Análise de remessas (média)</TitleC6>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        title="Análise de remessas (média)"
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="GraphSlaExpeditionTotal"
                                        modality="byTotal"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </Card>
                            <Card className="w-9/12 mt-4 flex-col justify-between flex">
                                <ABCAnalysisGraph
                                    modality="EXPEDICAO"
                                    title="Curva ABC - Expedição"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    containerId="ABCAnalysisGraphExpedicao"
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                                <DeliveryStatusGraph
                                    modality="STATUS_COLETA"
                                    title="Status de Coleta - Expedição"
                                    searchPeriod={searchPeriod}
                                    selectedClient={selectedClient}
                                    selectedUf={selectedUf}
                                    setMeta={setMeta}
                                    dashboard={true}
                                />
                                <S.Footer>
                                    <Link to="/armazem/expedicao">
                                        <S.Button>Ver expedição</S.Button>
                                    </Link>
                                </S.Footer>
                            </Card>
                        </>
                    )}
                </S.ContainerCards>
            </Layout>
            {openModalGraphReceipt && (
                <Modal
                    onClose={actionModalGraphReceipt}
                    size="xs:w-11/12 lg:w-8/12 min-h-20%"
                    show={openModalGraphReceipt}
                    scIn={false}
                    title={
                        <div className="flex items-center">
                            <h5
                                className={`mr-6 text-roxo_oficial font-bold`}>
                                Análise de remessas por dia {`${clientsOptions.find(arr => selectedClient === arr.value) ? "-" + " " + clientsOptions.find(arr => selectedClient === arr.value)?.label : ""}`}
                            </h5>
                        </div>
                    }
                >
                    <div className="w-full">
                        {!dataReceipt.average ? (
                            <>
                                <Card>
                                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                        <ClipLoader size={20} loading={true} />
                                    </div>
                                </Card>
                            </>
                        ) : (
                            <GraphWarehouseSla
                                // title="Análise de remessas por dia"
                                data={dataReceipt}
                                isLoading={loadReceipt}
                                selectedClient={selectedClient}
                                container="GraphSlaReceiptPeriod"
                                modality="byPeriod"
                                openModalGraphReceipt={openModalGraphReceipt}
                                actionModalGraphReceipt={actionModalGraphReceipt}
                            />
                        )}
                    </div>
                </Modal>
            )}
            {openModalGraphExpedition && (
                <Modal
                    onClose={actionModalGraphExpedition}
                    size="xs:w-11/12 lg:w-10/12 min-h-40%"
                    show={openModalGraphExpedition}
                    scIn={false}
                    title={
                        <div className="flex items-center">
                            <h5
                                onClick={() => setTabModal("Análise de remessas por dia")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por dia" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por dia
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por UF")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por UF" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por UF
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Cidade")}
                                className={`mr-6 cursor-pointer ${
                                    tabModal === "Análise de remessas por Cidade" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                }`}
                            >
                                Análise de remessas por Cidade
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Cliente")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por Cliente" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por Cliente
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Transportadora")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por Transportadora" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por Transportadora
                            </h5>
                        </div>
                    }
                >
                    <div className="w-full">
                        {tabModal === "Análise de remessas por dia" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="GraphSlaExpeditionPeriod"
                                        modality="byPeriodDispatched"
                                        additionalGraph={true}
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por UF" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="stateCode"
                                        modality="byStateCode"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Cidade" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="city"
                                        modality="byCity"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Cliente" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="byClient"
                                        modality="byClient"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Transportadora" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={loadExpedition}
                                        selectedClient={selectedClient}
                                        container="byShippingCompany"
                                        modality="byShippingCompany"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default IndexPage;
